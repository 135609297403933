import React from "react";
import "./services.css";
import { IoIosArrowDropdown } from "react-icons/io";
import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import workshopImg from "../../assets/img/online 4.JPG";
import tourImgTR from "../../assets/img/GEZİ TURU.jpg";
import tourImgEN from "../../assets/img/ISLAMIC ART TOUR.jpg";
import workshopImg2 from "../../assets/img/AMERIKA 7.jpg";
import workshopImg3 from "../../assets/img/VAWAA.jpeg";

const Services = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ee3hfxc",
        "template_s9avdlt",
        form.current,
        "fH7oIxi08YicXFYxw"
      )
      .then(
        (result) => {
          console.log("success");
        },
        (error) => {
          console.log("error");
        }
      );

    e.target.reset();
  };
  return (
    <div className="servicesContainer">
      <div className="servicesContainerWrap">
        <div className="banner servicesBanner">
          <div className="bannerWrap">
            <div className="bannerContent tr">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Talepler</span>
              </h1>
              <p>
                <a href="#servicesContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
            <div className="bannerContent en">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Requests</span>
              </h1>
              <p>
                <a href="#servicesContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="servicesContent" id="servicesContent">
          <div className="servicesMenu">
            <div className="serviceItem tourItem">
              <div className="serviceItemWrapper">
                <div className="serviceItemContent tr">
                  <h3 className="serviceItemSubHeader">
                    İslam Rehberi Eşliğinde
                  </h3>
                  <h3 className="serviceItemHeader">Gezi Turu</h3>
                  <a href="#tour">
                    <IoIosArrowDropdown />
                  </a>
                </div>
                <div className="serviceItemContent en">
                  <h3 className="serviceItemSubHeader">
                    Accompanied by the Islamic Guide
                  </h3>
                  <h3 className="serviceItemHeader">Tour</h3>
                  <a href="#tour">
                    <IoIosArrowDropdown />
                  </a>
                </div>
              </div>
            </div>
            <div className="serviceItem workshopItem">
              <div className="serviceItemWrapper">
                <div className="serviceItemContent tr">
                  <h3 className="serviceItemSubHeader">Çevrimiçi / Yüz yüze</h3>
                  <h3 className="serviceItemHeader">Sunum</h3>
                  <a href="#workshop">
                    <IoIosArrowDropdown />
                  </a>
                </div>
                <div className="serviceItemContent en">
                  <h3 className="serviceItemSubHeader">
                    Online / Face to Face
                  </h3>
                  <h3 className="serviceItemHeader">Workshop</h3>
                  <a href="#workshop">
                    <IoIosArrowDropdown />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="serviceOption" id="workshop">
            <div className="headerBox tr">
              <h2 className="serviceHeader">Sunum</h2>
            </div>
            <div className="headerBox en">
              <h2 className="serviceHeader">Workshop</h2>
            </div>
            <div className="serviceInfo">
              <div className="serviceImgBox">
                <img src={workshopImg} alt="serviceImg" />
              </div>
              <div className="serviceText tr">
                <span>
                  Online veya yüzyüze sunum talebinde bulunabilirsiniz.{" "}
                </span>
                <h3>Konular</h3>
                <p>
                  *Hat Sanatı tarihi ve yazı çeşitleri <br /> *Eski ustaların
                  hayatlarından ilginç hikayeler
                  <br /> *Hattat Halim Özyazıcı sergisi, Kazlıçeşme Sanat
                  Galerisi, 2021
                  <br /> *Kağıttan Çerçeveye: Bir Eserin Oluşma Aşamaları
                  <br /> *Celi Sülüs levhaları ve Hattat imzaları Nasıl Okunur?
                  <br /> *Hat Sanatında Usta-Çırak İlişkisinin Önemi
                  <br /> *Taksim Camii Yazıları
                  <br /> *Günümüzde organize edilen Hat Faaliyetleri
                  <br /> *Hattat Hasan Çelebi: Dünden Bugüne Retrospektif
                  Sergisi
                </p>
              </div>
              <div className="serviceText en">
                <span>
                  Online or face-to-face presentations can be requested.
                </span>
                <h3>Topics</h3>
                <p>
                  *Introduction to the history of calligraphy and the
                  development of different scripts
                  <br /> *Interesting anecdotes from lives of old masters
                  <br /> *Exhibition of Hattat Halim Özyazıcı
                  <br />
                  *From Paper to Frame: The Stages of Making a Final Artwork
                  <br />
                  *How to Read Jali Tuluth Pieces and Signatures of
                  Calligraphers
                  <br /> *The Significance of Master-Student Relationship:
                  Traditional Method of Learning Calligraphy
                  <br /> *The Calligraphy of Taksim Mosque, Istanbul.
                  <br /> *Contemporary Calligraphy Events
                  <br /> *The Retrospective Exhibition of Hattat Hasan Çelebi
                </p>
              </div>
              <div className="serviceDoubleImgBox">
                <img src={workshopImg2} alt="serviceImg" />
                <img src={workshopImg3} alt="serviceImg" />
              </div>
            </div>
          </div>
          <div className="serviceOption" id="tour">
            <div className="headerBox tr">
              <h2 className="serviceHeader">Gezi Turu</h2>
            </div>
            <div className="headerBox en">
              <h2 className="serviceHeader">Tour</h2>
            </div>
            <div className="serviceInfo">
              <div className="serviceImgBox tr">
                <img src={tourImgTR} alt="serviceImg" />
              </div>
              <div className="serviceImgBox en">
                <img src={tourImgEN} alt="serviceImg" />
              </div>
              <div className="serviceText tr">
                <h3>Gezi Turu</h3>
                <p>
                  Yurt dışından gelen sanatseverlere önemli müze ve camiilerde
                  yazı anlatmak üzere kısa gezi turunda rehberlik edilebilir.
                  Ayrıntılar için lütfen mail atınız.
                </p>
              </div>
              <div className="serviceText en">
                <h3>Tour</h3>
                <p>
                  Contact the artist for booking an{" "}
                  <span className="bold gold">"Islamic Art Tour"</span> of some
                  museums and art galleries. Details will be shared via email.
                </p>
              </div>
            </div>
          </div>
          <div className="serviceOption" id="serviceForm">
            <div className="headerBox tr">
              <h2 className="serviceHeader">İrtibata Geçin</h2>
            </div>
            <div className="headerBox en">
              <h2 className="serviceHeader">Get in Touch</h2>
            </div>
            <div className="serviceInfo">
              <div className="serviceForm">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="radioContainer">
                    <label htmlFor="tour_tr" className="tr">
                      <div className="radioBox">
                        <input
                          type="radio"
                          name="service_name"
                          className="tr"
                          id="tour_tr"
                        />
                      </div>
                      Gezi Turu
                    </label>
                    <label htmlFor="tour_en" className="en">
                      <div className="radioBox">
                        <input
                          type="radio"
                          name="service_name"
                          className="en"
                          placeholder="Tour"
                          id="tour_en"
                        />{" "}
                      </div>
                      Tour
                    </label>
                    <label htmlFor="workshop_tr" className="tr">
                      <div className="radioBox">
                        <input
                          type="radio"
                          name="service_name"
                          className="tr"
                          placeholder="Sunum"
                          id="workshop_tr"
                        />{" "}
                      </div>
                      Sunum
                    </label>
                    <label htmlFor="workshop_en" className="en">
                      <div className="radioBox">
                        <input
                          type="radio"
                          name="service_name"
                          className="en"
                          placeholder="Workshop"
                          id="workshop_en"
                        />{" "}
                      </div>
                      Workshop
                    </label>
                  </div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Ad Soyad*"
                    className="tr"
                  />
                  <input
                    type="text"
                    name="name"
                    placeholder="Name*"
                    className="en"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="E-Posta*"
                    className="tr"
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="E-Mail*"
                    className="en"
                  />
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Telefon Numarası"
                    className="tr"
                  />
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number"
                    className="en"
                  />
                  <textarea
                    name="message"
                    rows="7"
                    placeholder="Mesajınız*"
                    className="tr"
                  ></textarea>
                  <textarea
                    name="message"
                    rows="7"
                    placeholder="Your Message*"
                    className="en"
                  ></textarea>
                  <button type="submit" className="sendBtn" value="Send">
                    <span className="tr">Mesaj Gönderin</span>
                    <span className="en">Send Message</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
