import React from "react";
import "./materials.css";
import { IoIosArrowDropdown } from "react-icons/io";
import { useEffect } from "react";
import materialImg1 from "../../assets/img/IMG_6369.jpg";
import materialImg2 from "../../assets/img/7.jpg";
import materialImg3 from "../../assets/img/4 (1).jpg";
import materialImg4 from "../../assets/img/Lika.jpg";

const Materials = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);

  const materials = [
    {
      src: materialImg2,
      img_info_tr: "Kalem çeşitleri, hokkalar, bıçaklar, makta",
      img_info_en:
        "Variety of pens (reed, bamboo, java), inkwells, knives, platforms to be used for cutting the pen",
      name_tr: "Kalemler",
      name_en: "Pens",
      desc_tr:
        "Hat sanatında sıcak iklimlerde yetişen bazı kamış çeşitleri kullanılır. Kalemtraş adı verilen bıçaklarla kalem açılır, ve yazılacak yazıya/ebatına göre uygun ölçüye getirilir. Makta üzerine kalem yerleştirilerek ortasına 'şak' denilen çatlak açılır. Bunun sebebi ise mürekkebin akışını sağlamaktır. Kalemin ebatına göre bir veya birkaç çatlağa ihtiyaç duyulabilir. Celi (büyük) yazılarda bambu veya ona benzer sert malzemelerden özel olarak kalemler yapılıp kullanılır. Nesih gibi ince yazılarda java kalemleri de tercih edilmektedir.  Ayrıca madeni kalemler de kullanılmaktadır.",
      desc_en:
        "Islamic calligraphy is written with a pen (qalam) made of certain types of reed that grow in warm climates. The reed is sharpened with a special knife and its tip is reduced to the appropriate width and angle depending on the script to be written.  A small rectangular platform of ivory or bone is used for this purpose. The pen is laid on it, its tip is nipped and slit to permit the flow of the ink. Special pens carved out of wood or bamboo are used for large-sized inscriptions.  These pens require more slits for the ink to flow. Various sized reed pens as well as java pens are used for smaller scripts.  Great importance has been given to instruments and materials in an effort to attain the highest degree of artistic perfection. ",
    },
    {
      src: materialImg3,
      img_info_tr: "Mühre çeşitleri ve aherli kağıt",
      img_info_en: "Variety of burnishers made of agate stone and 'ahar' paper",
      name_tr: "Kağıt",
      name_en: "Paper",
      desc_tr:
        "El yapımı, asitsiz kağıtlar, çay veya kahve gibi renk verici malzemelerle renklendirilir. Üzerlerine suda kaynatılmış buğday nişastası (tercihen balık tutkalı eklenerek) sürülür. Ardından şap ve yumurta akından karıştırılarak elde edilen aher, bir veya birkaç kez kağıda uygulanır. Akik taşıyla yapılmış mührelerle kağıtlar parlatılır ve en az 1 sene veya daha uzun müddet bekletilir. Zaman geçtikçe aher iyice olgunlaşır ve kağıtlara yazı yazmak daha da kolaylaşır.",
      desc_en:
        "The acid-free paper used for writing is first dyed with a light colour produced by brewing tea or coffee (or boiling onion skins, walnut leaves....etc). After the desired shade is reached, it is coated with one layer of wheat starch (boiled in water with sheets of fish glue). In the last stage, the paper is coated with one or three layers of the mixture of egg-whites and alum called ahar. The paper is thoroughly polished with a burnisher made of an agate stone after it is completely dry.  Then the calligraphers need to let the paper age for as long as possible, at least a year. As time passes by (waiting several years is recommended), the paper absorbs everything put on it and becomes a soft surface to write on. The ahar allows the calligrapher to erase mistakes as well, though that is limited to one attempt only.",
    },
    {
      src: materialImg4,
      img_info_tr: "",
      img_info_en: "Raw silk used in the inkwell",
      name_tr: "Lika",
      name_en: "Lika",
    },
    {
      src: materialImg1,
      img_info_tr: "Varak altın, ezilmiş altın ve Arap zamkı",
      img_info_en: "Leaf gold, crushed gold, Gum Arabic",
      name_tr: "Tezhib",
      name_en: "Illumination",
      desc_tr:
        "Geleneksel sanatlarımızdan olan Tezhib sanatı, 'altınlamak' manası taşımaktadır. Bu sanatı icra eden kişiye 'müzehhib' adı verilir.  Müzehhibler, ezilmiş varak altın ve farklı renkler kullanarak çeşitli motifler çizer ve bunlardan kompozisyonlar oluşturur. Hattat, yazıyı yazdıktan sonra, etrafına süsleme isterse, bir müzehhib/müzehhibe ile irtibata geçer. Yazının etrafına ne yapılacağına karar verilir ve böylece eser tamamlanır.",
      desc_en:
        "After the calligrapher finishes the writing, the piece is wet-laminated on a 'murakka', which is a backing stretched onto a wooden board to keep it flat. Different colored paper is glued around the writing and a few days later the piece can be cut off from the wood. It is then usually given to an artist of illumination who draws golden borders around the piece and illuminates the piece using gold 'ink' which is produced by the grinding of gold leaf by hand mixed with gum Arabic.  This mixture needs to be strained until pure gold dust remains. The artist applies the gold with a brush and burnishes it with an agate stone.",
    },
  ];

  return (
    <div className="materialsContainer">
      <div className="materialsContainerWrap">
        <div className="banner materialsBanner">
          <div className="bannerWrap">
            <div className="bannerContent tr">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Malzemeler</span>
              </h1>
              <p>
                <a href="#materialsContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
            <div className="bannerContent en">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Materials</span>
              </h1>
              <p>
                <a href="#materialsContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="materialsContent" id="materialsContent">
          <div className="materialsContentWrap">
            <div className="grid-1">
              {materials.map((material, i) => (
                <div className="materialContainer" key={i}>
                  <div className="headerBox tr">
                    <h2 className="materialHeader">{material.name_tr}</h2>
                  </div>
                  <div className="headerBox en">
                    <h2 className="materialHeader">{material.name_en}</h2>
                  </div>
                  <div className="materialContent">
                    <div className="materialImgBox">
                      <img src={material.src} alt="materialImg" />
                    </div>
                    <div className="materialInfoBox">
                      <div className="materialInfo tr">
                        <span className="imgInfo">{material.img_info_tr}</span>
                        <h4>{material.name_tr}</h4>
                        <p>{material.desc_tr}</p>
                      </div>
                      <div className="materialInfo en">
                        <span className="imgInfo">{material.img_info_en}</span>
                        <h4>{material.name_en}</h4>
                        <p>{material.desc_en}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Materials;
