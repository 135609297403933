import React from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { useEffect } from "react";
import { useState } from "react";
import {
  AiOutlineClose,
  AiOutlineArrowRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import "./gallery.css";
import axios from "axios";

const Gallery = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);

  const [posts, setPosts] = useState([]);

  useEffect(() => {
    async function fetchPosts() {
      const { data } = await axios.get(
        "https://www.hattatdenizbektas.com/api/posts/"
      );
      setPosts(data);
    }
    fetchPosts();
  }, []);
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "left") {
      newSlideNumber = slideNumber === 0 ? posts.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === posts.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  return (
    <div className="galleryContainer">
      <div className="galleryContainerWrap">
        {open && (
          <div className="activePostImage">
            <AiOutlineClose
              className="closeBtn"
              onClick={() => setOpen(false)}
            />
            <AiOutlineArrowLeft
              className="arrow"
              onClick={() => handleMove("left")}
            />
            <div className="activeImgWrapper">
              <div className="imgWrapper">
                <div className="logoWrapper">
                  <h4 className="logoText">Deniz Öktem-Bektaş</h4>
                </div>
              </div>
              <img
                src={posts[slideNumber].post_image}
                alt="activeImg"
                className="activeImg"
              />
            </div>
            <AiOutlineArrowRight
              className="arrow"
              onClick={() => handleMove("right")}
            />
          </div>
        )}
        <div className="banner galleryBanner">
          <div className="bannerWrap">
            <div className="bannerContent tr">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Eserler</span>
              </h1>
              <p>
                <a href="#galleryContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
            <div className="bannerContent en">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Gallery</span>
              </h1>
              <p>
                <a href="#galleryContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="galleryContent" id="galleryContent">
          <div className="galleryContentWrap">
            <div className="grid-3">
              {posts.map((post, i) => (
                <div className="postContainer" key={i}>
                  <div className="postContent" onClick={() => handleOpen(i)}>
                    <div className="postImgBox">
                      <div className="imgWrapper">
                        <h4 className="logoText">Deniz Öktem-Bektaş</h4>
                      </div>
                      <img src={post.post_image} alt="postImg" />
                    </div>
                    <div className="postInfo tr">
                      <h4>{post.post_name_tr}</h4>
                      <p>{post.post_description_tr}</p>
                      <span>{post.post_info}</span>
                    </div>
                    <div className="postInfo en">
                      <h4>{post.post_name_en}</h4>
                      <p>{post.post_description_en}</p>
                      <span>{post.post_info}</span>
                    </div>
                  </div>
                </div>
              )).reverse()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
