import React from "react";
import "./footer.css";
import { Link, NavLink } from "react-router-dom";
import { BsWhatsapp, BsInstagram, BsLinkedin } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import footerLogo from "../../assets/img/signature_transparent_white.png";

const Footer = () => {
  return (
    <div className="footerWrap">
      <div className="footerContainer">
        <footer>
          <div className="contentWrap contentWrap-1">
            <div className="footerCol">
              <h3 className="footerHeader tr">Menü</h3>
              <h3 className="footerHeader en">Menu</h3>
              <ul className="footerNavList">
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/"
                  >
                    <span className="tr">Anasayfa</span>
                    <span className="en">Home</span>
                  </NavLink>
                </li>
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/about"
                  >
                    <span className="tr">Hakkında</span>
                    <span className="en">About</span>
                  </NavLink>
                </li>
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/gallery"
                  >
                    <span className="tr">Eserler</span>
                    <span className="en">Gallery</span>
                  </NavLink>
                </li>
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/services"
                  >
                    <span className="tr">Talepler</span>
                    <span className="en">Requests</span>
                  </NavLink>
                </li>
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/events"
                  >
                    <span className="tr">Faaliyetler</span>
                    <span className="en">Events</span>
                  </NavLink>
                </li>
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/materials"
                  >
                    <span className="tr">Malzemeler</span>
                    <span className="en">Materials</span>
                  </NavLink>
                </li>
                <li className="footerNavLink">
                  <NavLink
                    exact="true"
                    activeclassname="active"
                    className="footerNavLink"
                    style={({ isActive }) =>
                      isActive
                        ? { color: "var(--color-tan)" }
                        : { color: "var(--color-white)" }
                    }
                    to="/contact"
                  >
                    <span className="tr">İletişim</span>
                    <span className="en">Contact</span>
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footerCol">
              <div className="footerLogo">
                <img src={footerLogo} alt="footerLogo" />
              </div>
            </div>
            <div className="footerCol">
              <h3 className="footerHeader tr">İletişim</h3>
              <h3 className="footerHeader en">Contact</h3>
              <ul className="footerContactList">
                <li className="footerContactOption">
                  denizoktembektas@gmail.com
                </li>
                <li className="footerContactOption">@hattatdenizbektas</li>
                <li className="footerContactOption">+90 536 272 51 17</li>
                <li className="footerContactOption">
                  <button className="footerButton tr">
                    <NavLink
                      exact="true"
                      activeclassname="active"
                      className="navLinkFooter"
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "var(--color-white)",
                            }
                          : { color: "var(--color-white)" }
                      }
                      to="/contact"
                    >
                      <span>İletişim</span>
                    </NavLink>
                  </button>
                  <button className="footerButton en">
                    <NavLink
                      exact="true"
                      activeclassname="active"
                      className="navLinkFooter"
                      style={({ isActive }) =>
                        isActive
                          ? {
                              color: "var(--color-white)",
                            }
                          : { color: "var(--color-white)" }
                      }
                      to="/contact"
                    >
                      <span>Contact</span>
                    </NavLink>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="contentWrap contentWrap-2">
            <div className="footerCol-2">
              <div className="footerSocials">
                <div className="socialBox">
                  <a
                    href="https://www.instagram.com/hattatdenizbektas/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsInstagram />
                  </a>
                </div>
                <div className="socialBox">
                  <a
                    href="https://www.linkedin.com/in/deniz-%C3%B6ktem-bekta%C5%9F-755525261"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsLinkedin />
                  </a>
                </div>
                <div className="socialBox">
                  <a href="mailto:denizoktembektas@gmail.com">
                    <FiMail />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className="footerCopyright">
        <div className="copyrightBox tr">
          2022 © Telif Hakkı
          <span>Deniz Öktem-Bektaş</span>
          Tüm hakları saklıdır.
        </div>
        <div className="copyrightBox en">
          Copyright © 2022
          <span>Deniz Öktem-Bektaş</span>
          All rights reserved.
        </div>
        <div className="copyrightBox">Gökdeniz Abat</div>
      </div>
    </div>
  );
};

export default Footer;
