import React from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCreative } from "swiper";
import "./events.css";

const Events = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchEvents() {
      const { data } = await axios.get(
        "https://www.hattatdenizbektas.com/api/events/"
      );
      setEvents(data);
    }
    fetchEvents();
  }, []);

  return (
    <div className="eventsContainer">
      <div className="eventsContainerWrap">
        <div className="banner eventsBanner">
          <div className="bannerWrap">
            <div className="bannerContent tr">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Faaliyetler</span>
              </h1>
              <p>
                <a href="#eventsContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
            <div className="bannerContent en">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Events</span>
              </h1>
              <p>
                <a href="#eventsContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="eventsContent" id="eventsContent">
          <div className="eventsContentWrap">
            <div className="grid-1">
              {events.map((event, i) => (
                <div className="eventContainer" key={i}>
                  <div className="headerBox tr">
                    <h2 className="eventHeader">{event.event_info}</h2>
                  </div>
                  <div className="headerBox en">
                    <h2 className="eventHeader">{event.event_info}</h2>
                  </div>
                  <div className="eventContent">
                    <div className="eventSliderContainer">
                      <Swiper
                        effect={"creative"}
                        creativeEffect={{
                          prev: {
                            translate: ["-120%", 0, -200],
                          },
                          next: {
                            translate: ["120%", 0, -200],
                          },
                        }}
                        speed={600}
                        grabCursor={true}
                        navigation={true}
                        modules={[Navigation, EffectCreative]}
                        className="eventServiceSwiper"
                      >
                        <SwiperSlide>
                          <div className="eventImgBox">
                            <img src={event.event_image} alt="eventImg" />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="eventImgBox">
                            <img src={event.event_image_2} alt="eventImg" />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="eventImgBox">
                            <img src={event.event_image_3} alt="eventImg" />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="eventImgBox">
                            <img src={event.event_image_4} alt="eventImg" />
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                    <div className="eventInfoBox">
                      <div className="eventInfo tr">
                        <h4>{event.event_name_tr}</h4>
                        <p>{event.event_description_tr}</p>
                        <span>{event.event_info}</span>
                      </div>
                      <div className="eventInfo en">
                        <h4>{event.event_name_en}</h4>
                        <p>{event.event_description_en}</p>
                        <span>{event.event_info}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )).reverse()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
