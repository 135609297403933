import React from "react";
import "./home.css";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCards, Parallax } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-cards";
import serviceSlide from "../../assets/img/signature.jpeg";
import axios from "axios";

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);

  const [works, setWorks] = useState([]);

  useEffect(() => {
    async function fetchWorks() {
      const { data } = await axios.get(
        "https://www.hattatdenizbektas.com/api/posts/"
      );
      setWorks(data);
    }
    fetchWorks();
  }, []);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    async function fetchEvents() {
      const { data } = await axios.get(
        "https://www.hattatdenizbektas.com/api/events/"
      );
      setEvents(data);
    }
    fetchEvents();
  }, []);

  return (
    <div className="homeContainer">
      <div className="homeSliderContainer">
        <Swiper
          loop={true}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div
              slot="container-start"
              className="homeSlideContainer_1 parallax-bg"
              data-swiper-parallax="-23%"
            >
              <div className="slideContent tr">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Hattat</span>
                  </h1>
                  <p>"Yazı, dilin eli, elin dilidir."</p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">“Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Hat: Davut Bektaş, Taksim Camii, Istanbul
                </p>
              </div>
              <div className="slideContent en">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Islamic Calligrapher</span>
                  </h1>
                  <p>
                    “Writing is the hand of the tongue and the tongue of the
                    hand.”
                  </p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">from his book “Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Calligraphy of Taksim Mosque, Davut Bektaş
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              slot="container-start"
              className="homeSlideContainer_2 parallax-bg"
              data-swiper-parallax="-23%"
            >
              <div className="slideContent tr">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Hattat</span>
                  </h1>
                  <p>"Yazı, dilin eli, elin dilidir."</p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">“Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Hat: Davut Bektaş, Taksim Camii, Istanbul
                </p>
              </div>
              <div className="slideContent en">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Islamic Calligrapher</span>
                  </h1>
                  <p>
                    “Writing is the hand of the tongue and the tongue of the
                    hand.”
                  </p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">from his book “Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Calligraphy of Taksim Mosque, Davut Bektaş
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              slot="container-start"
              className="homeSlideContainer_3 parallax-bg"
              data-swiper-parallax="-23%"
            >
              <div className="slideContent tr">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Hattat</span>
                  </h1>
                  <p>"Yazı, dilin eli, elin dilidir."</p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">“Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Hat: Davut Bektaş, Taksim Camii, Istanbul
                </p>
              </div>
              <div className="slideContent en">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Islamic Calligrapher</span>
                  </h1>
                  <p>
                    “Writing is the hand of the tongue and the tongue of the
                    hand.”
                  </p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">from his book “Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Calligraphy of Taksim Mosque, Davut Bektaş
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div
              slot="container-start"
              className="homeSlideContainer_4 parallax-bg"
              data-swiper-parallax="-23%"
            >
              <div className="slideContent tr">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Hattat</span>
                  </h1>
                  <p>"Yazı, dilin eli, elin dilidir."</p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">“Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Hat: Davut Bektaş, Taksim Camii, Istanbul
                </p>
              </div>
              <div className="slideContent en">
                <div className="box">
                  <h2>
                    Deniz Öktem<span>-</span>Bektaş
                  </h2>
                  <h1>
                    <span>Islamic Calligrapher</span>
                  </h1>
                  <p>
                    “Writing is the hand of the tongue and the tongue of the
                    hand.”
                  </p>
                  <div className="inner-box">
                    <p className="right">Mahmud Bedreddin Yazır</p>
                    <p className="right">from his book “Kalem Güzeli”</p>
                  </div>
                </div>
                <p className="imgInfo light">
                  Calligraphy of Taksim Mosque, Davut Bektaş
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="homeGalleryContainer">
        <div className="homeSectionWrapper">
          <div className="sectionHeaderBox tr">
            <h2>Son Eserler</h2>
            <a href="gallery">
              <h3>Daha Fazlası...</h3>
            </a>
          </div>
          <div className="sectionHeaderBox en">
            <h2>Latest Works</h2>
            <h3>More...</h3>
          </div>
          <div className="homeGallerySliderContainer triple">
            <Swiper
              slidesPerView={3}
              spaceBetween={0}
              grabCursor={true}
              slidesPerGroup={1}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="myServiceSwiper"
            >
              {works
                .filter((work, idx) => idx < 6)
                .map((work, i) => (
                  <SwiperSlide>
                    <div className="gallerySlideContainer" key={i}>
                      <div className="gallerySlideContentWrapper">
                        <img src={work.post_image} alt="gallerySlide" />
                      </div>
                      <div className="gallerySlideContent tr">
                        <h3>{work.post_name_tr}</h3>
                        <h4>{work.post_date}</h4>
                      </div>
                      <div className="gallerySlideContent en">
                        <h3>{work.post_name_en}</h3>
                        <h4>{work.post_date}</h4>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className="homeGallerySliderContainer dual">
            <Swiper
              slidesPerView={2}
              spaceBetween={0}
              grabCursor={true}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="myServiceSwiper"
            >
              {works
                .filter((work, idx) => idx < 6)
                .map((work, i) => (
                  <SwiperSlide>
                    <div className="gallerySlideContainer" key={i}>
                      <img src={work.post_image} alt="gallerySlide" />
                      <div className="gallerySlideContent tr">
                        <h3>{work.post_name_tr}</h3>
                        <h4>{work.post_date}</h4>
                      </div>
                      <div className="gallerySlideContent en">
                        <h3>{work.post_name_en}</h3>
                        <h4>{work.post_date}</h4>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className="homeGallerySliderContainer single">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              grabCursor={true}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="myServiceSwiper"
            >
              {works
                .filter((work, idx) => idx < 6)
                .map((work, i) => (
                  <SwiperSlide>
                    <div className="gallerySlideContainer" key={i}>
                      <img src={work.post_image} alt="gallerySlide" />
                      <div className="gallerySlideContent tr">
                        <h3>{work.post_name_tr}</h3>
                        <h4>{work.post_date}</h4>
                      </div>
                      <div className="gallerySlideContent en">
                        <h3>{work.post_name_en}</h3>
                        <h4>{work.post_date}</h4>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className="homeEventsContainer">
        <div className="homeSectionWrapper">
          <div className="sectionHeaderBox tr">
            <h2>
              Son Programlar <span>/</span> Sergiler
            </h2>
            <a href="events">
              <h3>Daha Fazlası...</h3>
            </a>
          </div>
          <div className="sectionHeaderBox en">
            <h2>Latest Events</h2>
            <a href="events">
              <h3>More...</h3>
            </a>
          </div>
          <div className="homeEventsSliderContainer dual">
            <Swiper
              slidesPerView={2}
              spaceBetween={0}
              grabCursor={true}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="myServiceSwiper"
            >
              {events
                .filter((event, idx) => idx < 4)
                .map((event, i) => (
                  <SwiperSlide>
                    <div className="eventsSlideContainer" key={i}>
                      <img src={event.event_image} alt="eventSlide" />
                      <div className="eventsSlideContent">
                        <div className="slideContentWrapper tr">
                          <h3>{event.event_name_tr}</h3>
                          <p className="gold">{event.event_info}</p>
                        </div>
                        <div className="slideContentWrapper en">
                          <h3>{event.event_name_en}</h3>
                          <p className="gold">{event.event_info}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className="homeEventsSliderContainer single">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              grabCursor={true}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="myServiceSwiper"
            >
              {events
                .filter((event, idx) => idx < 4)
                .map((event, i) => (
                  <SwiperSlide>
                    <div className="eventsSlideContainer" key={i}>
                      <img src={event.event_image} alt="eventSlide" />
                      <div className="eventsSlideContent tr">
                        <div className="slideContentWrapper tr">
                          <h3>{event.event_name_tr}</h3>
                          <p className="gold">{event.event_info}</p>
                        </div>
                      </div>
                      <div className="eventSlideContent en">
                        <div className="slideContentWrapper en">
                          <h3>{event.event_name_en}</h3>
                          <p className="gold">{event.event_info}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className="aboutWrapper">
            <div className="imgBox">
              <img src={serviceSlide} alt="homeAboutImg" />
            </div>
            <div className="contentWrapper">
              <div className="contentBox tr">
                <h3>Deniz Öktem-Bektaş</h3>
                <p>
                  1976 yılında Hollanda’da doğdu. 1994’te Üsküdar İmam-Hatip
                  Lisesi’ni bitirdi. 2000’de Boğaziçi Üniversitesi İngiliz Dili
                  ve Edebiyatı bölümü’nden mezun olduktan sonra Amerika’da
                  Washington D.C.’de Georgetown Üniversitesi’nde İletişim,
                  Kültür ve Teknoloji bölümü’nde yüksek lisans yaptı. Eğitimi
                  sırasında Türk Dili bölümü'nün asistanlığını yürüttü ve özel
                  Türkçe dil dersleri verdi. 2003'te İngiltere'de SOAS
                  University of London'ın Tarih bölümü'nde Hat sanatı konulu
                  doktora proğramına kabul edildi. Ancak Amerika’daki eğitimi
                  sırasında İslam Hat Sanatı’na ilgi duyarak Mohamed Zakariya
                  ile rik’a ve nesih çalışmaya başladığı için, tamamiyle Hat
                  sanatına yönelmek üzere İstanbul’a döndü.
                </p>
                <a href="about" className="readMore gold">
                  Devamı...
                </a>
              </div>
              <div className="contentBox en">
                <h3>Deniz Öktem-Bektaş</h3>
                <p>
                  Deniz Öktem Bektaş was born in Holland in 1976. She graduated
                  from ‘İmam-Hatip’ high-school in Istanbul in 1994. After
                  receiving a Bachelor's degree at the Department of English
                  Literature at Boğaziçi University, she continued her graduate
                  work at Georgetown University in the U.S.A. While working on
                  her masters at the Department of Communication, Culture and
                  Technology in Washington D.C., she met Mohamed Zakariya with
                  whom she started calligraphy lessons in riq’a and naskh in
                  2001. She became the assistant for the Turkish classes at
                  Georgetown University. With that experience, she continued to
                  give private lessons in Turkish and provided freelance service
                  for various companies that needed a translator for Turkish in
                  their projects.
                </p>
                <a href="about" className="readMore gold">
                  More...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
