import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import Navbar from './components/Navbar/Navbar';
import Layout from './components/Layout/Layout';
import About from './pages/About/About';
import Gallery from './pages/Gallery/Gallery';
import Events from './pages/Events/Events';
import Materials from './pages/Materials/Materials';
import Contact from './pages/Contact/Contact';
import Services from './pages/Services/Services';

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />}/>
          <Route path="about" element={<About />}/>
          <Route path="gallery" element={<Gallery />}/>
          <Route path='services' element={<Services />} />
          <Route path='events' element={<Events />}/>
          <Route path='materials' element={<Materials />}/>
          <Route path='contact' element={<Contact />}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
