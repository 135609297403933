import React from "react";
import { useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./navbar.css";
import { FaBars } from "react-icons/fa";
import logoImg from "../../assets/img/signature_transparent_white.png";

const Navbar = () => {
  const navRef = useRef();
  const navContainerRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("navActive");
  };

  window.onscroll = () => {
    if (window.scrollY > 0) {
      navContainerRef.current.classList.add("navUnstable");
    } else {
      navContainerRef.current.classList.remove("navUnstable");
    }
  };

  const trContent = document.getElementsByClassName("tr");
  const enContent = document.getElementsByClassName("en");

  localStorage.setItem("language", "tr");

  const langSwitchEn = () => {
    for (let i = 0; i < trContent.length; i++) {
      trContent[i].classList.add("langOff");
      trContent[i].classList.remove("langOn");
    }
    for (let i = 0; i < enContent.length; i++) {
      enContent[i].classList.add("langOn");
      enContent[i].classList.add("langOff");
    }

    localStorage.setItem("language", "en");
  };
  const langSwitchTr = () => {
    for (let i = 0; i < trContent.length; i++) {
      trContent[i].classList.add("langOn");
      trContent[i].classList.remove("langOff");
    }
    for (let i = 0; i < enContent.length; i++) {
      enContent[i].classList.add("langOff");
      enContent[i].classList.remove("langOn");
    }

    localStorage.setItem("language", "tr");
  };
  return (
    <div className="navContainer">
      <div ref={navContainerRef} className="navbar">
        <Link className="navLogo navLink" to="/">
          <div className="logoImgBox">
            <img src={logoImg} alt="navLogo" />
          </div>
        </Link>
        <div className="burger">
          <FaBars className="burgerIcon" onClick={showNavbar} />
        </div>
        <nav ref={navRef} className="mainNav">
          <div className="burger">
            <FaBars className="burgerIconActive" onClick={showNavbar} />
          </div>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navLink"
            style={({ isActive }) =>
              isActive
                ? { color: "var(--color-gold)" }
                : { color: "var(--color-white)" }
            }
            to="/"
          >
            <span className="tr">Anasayfa</span>
            <span className="en">Home</span>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navLink"
            style={({ isActive }) =>
              isActive
                ? { color: "var(--color-gold)" }
                : { color: "var(--color-white)" }
            }
            to="/about"
          >
            <span className="tr">Hakkında</span>
            <span className="en">About</span>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navLink"
            style={({ isActive }) =>
              isActive
                ? { color: "var(--color-gold)" }
                : { color: "var(--color-white)" }
            }
            to="/gallery"
          >
            <span className="tr">Eserler</span>
            <span className="en">Gallery</span>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navLink"
            style={({ isActive }) =>
              isActive
                ? { color: "var(--color-gold)" }
                : { color: "var(--color-white)" }
            }
            to="/services"
          >
            <span className="tr">Talepler</span>
            <span className="en">Requests</span>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navLink"
            style={({ isActive }) =>
              isActive
                ? { color: "var(--color-gold)" }
                : { color: "var(--color-white)" }
            }
            to="/events"
          >
            <span className="tr">Faaliyetler</span>
            <span className="en">Events</span>
          </NavLink>
          <NavLink
            exact="true"
            activeclassname="active"
            className="navLink"
            style={({ isActive }) =>
              isActive
                ? { color: "var(--color-gold)" }
                : { color: "var(--color-white)" }
            }
            to="/materials"
          >
            <span className="tr">Malzemeler</span>
            <span className="en">Materials</span>
          </NavLink>
          <button className="contactButton tr">
            <NavLink
              exact="true"
              activeclassname="active"
              className="navLink"
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "var(--color-white)",
                    }
                  : { color: "var(--color-white)" }
              }
              to="/contact"
            >
              <span>İletişim</span>
            </NavLink>
          </button>
          <button className="contactButton en">
            <NavLink
              exact="true"
              activeclassname="active"
              className="navLink"
              style={({ isActive }) =>
                isActive
                  ? {
                      color: "var(--color-white)",
                    }
                  : { color: "var(--color-white)" }
              }
              to="/contact"
            >
              <span>Contact</span>
            </NavLink>
          </button>
          <div className="languageBox navLink">
            <span className="switchButton tr" onClick={langSwitchEn}>
              EN
            </span>
            <span className="switchButton en" onClick={langSwitchTr}>
              TR
            </span>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
