import React from "react";
import { IoIosArrowDropdown } from "react-icons/io";
import { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { BsInstagram, BsTelephone } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { AiOutlineWarning } from "react-icons/ai";
import "./contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ee3hfxc",
        "template_s9avdlt",
        form.current,
        "fH7oIxi08YicXFYxw"
      )
      .then(
        (result) => {
          console.log("success");
        },
        (error) => {
          console.log("error");
        }
      );

    e.target.reset();
  };
  return (
    <div className="contactContainer">
      <div className="contactContainerWrap">
        <div className="banner contactBanner">
          <div className="bannerWrap">
            <div className="bannerContent tr">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>İletişim</span>
              </h1>
              <p>
                <a href="#contactForm">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
            <div className="bannerContent en">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Contact</span>
              </h1>
              <p>
                <a href="#contactForm">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="contactContent">
          <div className="contactOptions">
            <div className="headerBox">
              <h2 className="tr">İrtibata Geçin</h2>
              <h2 className="en">Get in Touch</h2>
            </div>
            <div className="contactForm" id="contactForm">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="text"
                  name="name"
                  placeholder="Ad Soyad*"
                  className="tr"
                />
                <input
                  type="text"
                  name="name"
                  placeholder="Name*"
                  className="en"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="E-Posta*"
                  className="tr"
                />
                <input
                  type="email"
                  name="email"
                  placeholder="E-Mail*"
                  className="en"
                />
                <input
                  type="phone"
                  name="phone"
                  placeholder="Telefon Numarası"
                  className="tr"
                />
                <input
                  type="phone"
                  name="phone"
                  placeholder="Phone Number"
                  className="en"
                />
                <textarea
                  name="message"
                  rows="7"
                  placeholder="Mesajınız*"
                  className="tr"
                ></textarea>
                <textarea
                  name="message"
                  rows="7"
                  placeholder="Your Message*"
                  className="en"
                ></textarea>
                <button type="submit" className="sendBtn" value="Send">
                  <span className="tr">Mesaj Gönderin</span>
                  <span className="en">Send Message</span>
                </button>
              </form>
            </div>
            <div className="contactBoxes">
              <div className="contactBox">
                <article className="contactOption">
                  <FiMail className="contactOption-icon" />
                  <p>denizoktembektas@gmail.com</p>
                  <a href="mailto:denizoktembektas@gmail.com" className="tr">
                    Mesaj gönder
                  </a>
                  <a href="mailto:denizoktembektas@gmail.com" className="en">
                    Send Message
                  </a>
                </article>
              </div>
              <div className="contactBox">
                <article className="contactOption">
                  <BsInstagram className="contactOption-icon" />
                  <p>@hattatdenizbektas</p>
                  <a
                    href="https://www.instagram.com/hattatdenizbektas/"
                    target="_blank"
                    rel="noreferrer"
                    className="tr"
                  >
                    Hesaba Git
                  </a>
                  <a
                    href="https://www.instagram.com/hattatdenizbektas/"
                    target="_blank"
                    rel="noreferrer"
                    className="en"
                  >
                    View Account
                  </a>
                </article>
              </div>
              <div className="contactBox">
                <article className="contactOption">
                  <BsTelephone className="contactOption-icon" />
                  <p>+90 536 272 51 17</p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
