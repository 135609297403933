import React from "react";
import { useEffect } from "react";
import "./about.css";
import { IoIosArrowDropdown } from "react-icons/io";
import aboutImg1 from "../../assets/img/IMG_6371.jpg";
import aboutImg2 from "../../assets/img/Hasan Çelebi ve Mohamed Zakariya.JPG";
import aboutImg3 from "../../assets/img/Mohamed Zakariya ve Davut Bektaş.jpg";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const trContent = document.getElementsByClassName("tr");
    const enContent = document.getElementsByClassName("en");
    const lang = localStorage.getItem("language");

    if (lang === "tr") {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOn");
        trContent[i].classList.remove("langOff");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOff");
        enContent[i].classList.remove("langOn");
      }
    } else {
      for (let i = 0; i < trContent.length; i++) {
        trContent[i].classList.add("langOff");
        trContent[i].classList.remove("langOn");
      }
      for (let i = 0; i < enContent.length; i++) {
        enContent[i].classList.add("langOn");
        enContent[i].classList.add("langOff");
      }
    }
  }, []);
  return (
    <div className="aboutContainer">
      <div className="aboutContainerWrap">
        <div className="banner aboutBanner">
          <div className="bannerWrap">
            <div className="bannerContent tr">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>Hakkında</span>
              </h1>
              <p>
                <a href="#aboutContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
            <div className="bannerContent en">
              <h2>
                Deniz Öktem<span>-</span>Bektaş
              </h2>
              <h1>
                <span>About</span>
              </h1>
              <p>
                <a href="#aboutContent">
                  <IoIosArrowDropdown />
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="aboutContent" id="aboutContent">
          <div className="aboutInfo">
            <div className="aboutImgBox">
              <img src={aboutImg1} alt="aboutImg-1" />
              <span className="imgInfo dark italic">
                {" "}
                Mohamed Zakariya & Deniz Bektaş, Virginia, USA, 2001
              </span>
            </div>
            <div className="aboutContentBox tr">
              <div className="headerZone">
                <h3>Hakkında</h3>
              </div>
              <div className="textZone">
                <p>
                  1976 yılında Hollanda’da doğdu. 1994’te Üsküdar İmam-Hatip
                  Lisesi’ni bitirdi. 2000’de Boğaziçi Üniversitesi İngiliz Dili
                  ve Edebiyatı bölümü’nden mezun olduktan sonra Amerika’da
                  Washington D.C.’de Georgetown Üniversitesi’nde İletişim,
                  Kültür ve Teknoloji bölümü’nde yüksek lisans yaptı. Eğitimi
                  sırasında Türk Dili bölümü'nün asistanlığını yürüttü ve özel
                  Türkçe dil dersleri verdi. 2003'te İngiltere'de SOAS
                  University of London'ın Tarih bölümü'nde Hat sanatı konulu
                  doktora proğramına kabul edildi. Ancak Amerika’daki eğitimi
                  sırasında İslam Hat Sanatı’na ilgi duyarak Mohamed Zakariya
                  ile rik’a ve nesih çalışmaya başladığı için, tamamiyle Hat
                  sanatına yönelmek üzere İstanbul’a döndü.
                </p>
              </div>
            </div>
            <div className="aboutContentBox en">
              <div className="headerZone">
                <h3>About</h3>
              </div>
              <div className="textZone">
                <p>
                  Deniz Öktem Bektaş was born in Holland in 1976. She graduated
                  from ‘İmam-Hatip’ high-school in Istanbul in 1994. After
                  receiving a Bachelor's degree at the Department of English
                  Literature at Boğaziçi University, she continued her graduate
                  work at Georgetown University in the U.S.A. While working on
                  her masters at the Department of Communication, Culture and
                  Technology in Washington D.C., she met Mohamed Zakariya with
                  whom she started calligraphy lessons in riq’a and naskh in
                  2001. She became the assistant for the Turkish classes at
                  Georgetown University. With that experience, she continued to
                  give private lessons in Turkish and provided freelance service
                  for various companies that needed a translator for Turkish in
                  their projects. After receiving her master's degree, she was
                  admitted to the PhD program in the department of History at
                  SOAS University of London to receive a doctorate degree in the
                  history of calligraphy. However, realising her strong passion
                  for art, she decided to focus on a training as a calligrapher.
                  Moving back to Turkey she continued to take calligraphy
                  lessons with Hasan Çelebi and Davut Bektaş in the scripts of
                  riq’a, naskh and thuluth. While intensively working on
                  calligraphy, she also did private tutoring in English and
                  translated books from English into Turkish.
                </p>
              </div>
            </div>
          </div>
          <div className="aboutInfo">
            <div className="aboutImgBox">
              <img src={aboutImg2} alt="aboutImg-1" />
              <span className="imgInfo dark italic tr">
                {" "}
                Hattat Mohamed Zakariya & Hattat Hasan Çelebi, Istanbul.
                Fotoğraf: Fatma Bagali
              </span>
              <span className="imgInfo dark italic en">
                {" "}
                Hattat Mohamed Zakariya & Hattat Hasan Çelebi, Istanbul. Photo
                by: Fatma Bagali
              </span>
            </div>
            <div className="aboutContentBox tr">
              <div className="textZone">
                <p>
                  İstanbul'da Hasan Çelebi ve Davut Bektaş ile rik’a, nesih ve
                  sülüs meşklerine devam etti. Bir yandan da özel İngilizce
                  dersleri vermeye ve İngilizce'den Türkçe'ye kitap çevirmeye
                  başladı. Yoğun hat çalışmalarını sürdürerek 2007’de IRCICA’da
                  düzenlenen törenle üç hocasının da imzaladığı sülüs-nesih
                  icazetini aldı. 2007 yılında IRCICA’nın 7. Uluslararası Hat
                  Yarışması’nda ve 2008’de Şarja Hat Bienali’nde nesih hattında
                  teşvik ödülleri aldı. Kültür ve Turizm Bakanlığı'nın
                  düzenlediği 17. Devlet Türk Süsleme Sanatları Yarışması'nda
                  Hüsn-i Hat dalında başarı ödülüne layık görüldü. 2015’te
                  Diyanet İşleri Başkanlığı’nın düzenlediği Ulusal Hat
                  Yarışması’nda 3.’lük ödülü aldı. 2011'de Dubai'de Kültür ve
                  Turizm Bakanlığı'nın düzenlediği "Ramazan Buluşması-Kur'an-ı
                  Kerim yazma" proğramında seçilen 30 hattattan biri olarak bir
                  cüz yazdı. Çamlıca Camii için yine seçilen 36 hattat arasında
                  yer alarak bir mushaf yazılmasına 1. cüz ile katkıda bulundu.
                  Yurt içinde ve yurt dışında karma sergilere katıldı. Halen
                  eser üretmekte, sergilere katılmakta, ve aynı zamanda
                  uluslararası online Hat dersleri vermektedir.
                </p>
              </div>
            </div>
            <div className="aboutContentBox en">
              <div className="textZone">
                <p>
                  In 2007, she was granted her ijaza in the naskh and thuluth
                  scripts. She received encouragement awards in naskh at the 7th
                  International Calligraphy Competition organized by IRCICA in
                  2007 and at the Calligraphy Biennial in Sharjah in 2008. She
                  received another award at the 17th Turkish Ornamentation Arts
                  Competition organized by the Turkish Ministry of Culture. In
                  the national calligraphy competition conducted by the
                  Presidency of Religious Affairs of Turkey (2015), she received
                  3rd prize in writing several pages from the Qur’an in the
                  script of naskh. In 2011, in the program," Multaqa Ramadan,"
                  she received the privilege of writing one juz becoming one of
                  the 30 calligraphers who wrote a joint Qur'an for the Ministry
                  of Culture and Tourism of Dubai. After this valuable
                  experience, she was chosen as one of the 36 female
                  calligraphers who also wrote a joint Qur'an for Çamlıca Mosque
                  in Istanbul. Mrs. Öktem participates in national and
                  international exhibitions with her original art pieces and
                  continues her work alongside her husband, Davut Bektaş, in
                  their studio in Istanbul. She currently holds international
                  calligraphy workshops online, supports the training of
                  calligraphy candidates from various countries through her
                  weekly online classes and gives calligraphy related lectures
                  in Istanbul and to a wider audience on an international scale.
                </p>
              </div>
            </div>
          </div>
          <div className="aboutInfo">
            <div className="aboutImgBox">
              <img src={aboutImg3} alt="aboutImg-1" />
              <span className="imgInfo dark italic">
                {" "}
                Mohamed Zakariya & Davut Bektaş, Virginia, USA, 2015
              </span>
            </div>
            <div className="aboutContentBox tr">
              <div className="textZone">
                <h4>Çevirdiği Kitaplar</h4>
                <p>
                  1- <span>Hatıralar: Tanıdıklarım,</span> Arnold J. Toynbee,
                  Klasik yayınları, 2005.
                </p>
                <p>
                  2-{" "}
                  <span>Modern Türkiye'nin Kısa Tarihi: Çıplak Türkiye,</span>{" "}
                  Nicole & Hugh Pope, Gelenek yayınları, 2004.
                </p>
                <p>
                  3- <span>Marksizm ve Faşizm: Janus'un Yüzleri,</span> A. James
                  Gregor, Gelenek yayınları, 2003.
                </p>
                <p>
                  4- <span>Avrupa Amerika Müslümanları,</span> John. L.
                  Esposito, Larry Poston, Yvonne Haddad, S. Sayyid, Shireen T.
                  Hunter, Huma Malik, Marciea Hermansen, Gelenek yayınları,
                  2003.
                </p>
              </div>
            </div>
            <div className="aboutContentBox en">
              <div className="textZone">
                <h4>Translations</h4>
                <p>
                  1- <span>Acquaintances</span> Arnold J. Toynbee, Klasik
                  yayınları, 2005.
                </p>
                <p>
                  2-
                  <span>
                    History Unveiled: A History of Modern Turkey, Nicole & Hugh
                    Pope,
                  </span>{" "}
                  Nicole & Hugh Pope, Gelenek yayınları, 2004.
                </p>
                <p>
                  3- <span>The Faces of Janus,</span> A. James Gregor, Gelenek
                  yayınları, 2003.
                </p>
                <p>
                  4- <span>Muslims of Europe and America,</span> John. L.
                  Esposito, Larry Poston, Yvonne Haddad, S. Sayyid, Shireen T.
                  Hunter, Huma Malik, Marciea Hermansen, Gelenek yayınları,
                  2003.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
